import './Home.css';
import { Link } from 'react-router-dom';


function Home() {
  return (
    <div className="Home">

    </div>
  );
}

export default Home;